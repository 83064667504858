import styled from '@emotion/styled';
import { Phase as PhaseModel, StepService, StepTemplate } from '@gbhem/api';
import { Route, useHistory, useRouteMatch } from 'react-router-dom';
import tw from 'twin.macro';

import { Can } from '../../providers';
import { Dismissable } from '../Dismissable';
import { Locked, More } from '../icons';
import { Modal } from '../Modal';
import { Option, OptionsMenu } from '../OptionsMenu';
import { StepEditor } from '../StepEditor';

interface StepProperties {
  step: StepTemplate;
  phase: PhaseModel;
  readOnly: boolean;
  isSystemTrack: boolean;
  onChange: (step: StepTemplate) => void;
}

export const StepContainer = styled.div<{
  readOnly?: boolean;
}>`
  ${tw`flex p-2 border border-neutral-400 items-center text-sm `}

  ${(p) => (!p.readOnly ? tw`bg-neutral-1000` : tw`bg-neutral-700`)}
`;

export function Step({ step, phase, isSystemTrack, readOnly, onChange }: StepProperties) {
  const router = useHistory();
  const { url } = useRouteMatch();

  return (
    <StepContainer
      title={
        step.originStepTemplate
          ? 'This step is locked, the only field that can be edited is prerequisites'
          : undefined
      }
      readOnly={readOnly}
      key={step.id}
    >
      {readOnly && <Locked className="h-4 w-4 mr-2 mb-0.5" />}
      <div className="flex-1 font-semibold">{step.name}</div>
      <Can do="update" on="TrackTemplate">
        <Dismissable control={<More />}>
          <OptionsMenu>
            <Option onClick={() => router.push(`${url}/phase/${phase.id}/step/${step.id}`)}>
              Edit
            </Option>
          </OptionsMenu>
        </Dismissable>
      </Can>
      <Route exact path={`${url}/phase/${phase.id}/step/${step.id}`}>
        <Can do="update" on="TrackTemplate">
          <Modal detectClickAway={false} name="Edit Step" onClose={() => void router.push(url)}>
            {({ close }) => (
              <StepEditor
                readOnly={readOnly}
                phase={phase}
                step={step}
                isSystemTrack={isSystemTrack}
                onSubmit={async (changes) => {
                  //Can only update prerequisites if step template is a copy
                  const updates = !changes.originStepTemplate
                    ? await StepService.updateStepTemplate(changes)
                    : await StepService.updateStepTemplatePrerequisites(
                        changes.id,
                        changes.prerequisites.map((prerequisite) => prerequisite.id)
                      );

                  updates.prerequisites = changes.prerequisites;

                  onChange({ ...updates, id: step.id });

                  phase.stepTemplates = phase.stepTemplates.filter((s) =>
                    s.id === step.id ? updates : s
                  );

                  close();
                }}
              />
            )}
          </Modal>
        </Can>
      </Route>
    </StepContainer>
  );
}

export default Step;
